/* roboto-100normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local('Roboto Thin '), local('Roboto-Thin'), url('../../node_modules/typeface-roboto/files/roboto-latin-100.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-100.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local('Roboto Thin italic'), local('Roboto-Thinitalic'), url('../../node_modules/typeface-roboto/files/roboto-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-100italic.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-300normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Roboto Light '), local('Roboto-Light'), url('../../node_modules/typeface-roboto/files/roboto-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-300.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local('Roboto Light italic'), local('Roboto-Lightitalic'), url('../../node_modules/typeface-roboto/files/roboto-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-300italic.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-400normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Roboto Regular '), local('Roboto-Regular'), url('../../node_modules/typeface-roboto/files/roboto-latin-400.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-400.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-400italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local('Roboto Regular italic'), local('Roboto-Regularitalic'), url('../../node_modules/typeface-roboto/files/roboto-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-400italic.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-500normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Roboto Medium '), local('Roboto-Medium'), url('../../node_modules/typeface-roboto/files/roboto-latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-500.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local('Roboto Medium italic'), local('Roboto-Mediumitalic'), url('../../node_modules/typeface-roboto/files/roboto-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-500italic.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-700normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Roboto Bold '), local('Roboto-Bold'), url('../../node_modules/typeface-roboto/files/roboto-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-700.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local('Roboto Bold italic'), local('Roboto-Bolditalic'), url('../../node_modules/typeface-roboto/files/roboto-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-700italic.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-900normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local('Roboto Black '), local('Roboto-Black'), url('../../node_modules/typeface-roboto/files/roboto-latin-900.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-900.woff') format('woff');
  /* Modern Browsers */
}
/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local('Roboto Black italic'), local('Roboto-Blackitalic'), url('../../node_modules/typeface-roboto/files/roboto-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */ url('../../node_modules/typeface-roboto/files/roboto-latin-900italic.woff') format('woff');
  /* Modern Browsers */
}
body,
.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100vw;
}
iframe {
  color-scheme: light;
}
[data-theme='light'] {
  color-scheme: light;
}
[data-theme='dark'] {
  color-scheme: dark;
}
[data-theme='dark'] input:-webkit-autofill,
[data-theme='dark'] input:-webkit-autofill:hover,
[data-theme='dark'] input:-webkit-autofill:focus,
[data-theme='dark'] textarea:-webkit-autofill,
[data-theme='dark'] textarea:-webkit-autofill:hover,
[data-theme='dark'] textarea:-webkit-autofill:focus,
[data-theme='dark'] select:-webkit-autofill,
[data-theme='dark'] select:-webkit-autofill:hover,
[data-theme='dark'] select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.clear-float {
  clear: both;
}
.button-wrapper {
  margin: 20px 0;
  text-align: center;
}
.align-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.align-left-center {
  align-items: center;
  display: flex;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis-wrapper {
  min-width: 0;
}
.form-wrapper {
  text-align: center;
}
.center-align {
  text-align: center;
}
.spinner {
  height: 100%;
  width: 100%;
}
.desktop .copyright {
  margin-bottom: 96px;
}
.track-active.track-active {
  font-weight: bold;
}
google-cast-launcher {
  --connected-color: #0d904f;
  height: 24px;
  width: 24px;
}
.player-control-maximized google-cast-launcher {
  --disconnected-color: #ffffff;
}
.desktop google-cast-launcher {
  align-self: flex-end;
  display: inherit;
  margin: 12px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a.no-underline {
  text-decoration: none;
}
.pointer-events-none {
  pointer-events: none;
}
.user-select-none {
  user-select: none;
}
.full-width {
  width: 100%;
}
.uppercase {
  text-transform: uppercase;
}
.strike {
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-decoration: line-through;
}
.album-art {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.album-art.normal {
  padding: 2px;
}
.desktop .album-art.normal {
  min-height: 200px;
  min-width: 200px;
}
.album-art.promoted .play-button-icon {
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  z-index: 10;
}
.desktop .album-art .play-button-icon {
  display: none;
}
.desktop .album-art:hover .play-button-icon {
  display: flex;
}
.album-art-image,
.album-art-image img,
.album-art-image picture {
  aspect-ratio: 1 / 1;
  display: inherit;
  min-height: 130px;
  min-width: 130px;
  width: 100%;
}
.album-art .album-details {
  padding: 7.5px 5px;
  width: calc(100% - 30px);
}
.album-art .bottom-section {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.desktop .album-art .bottom-section {
  padding: 5px 10px;
}
.album-art .bottom-section .song-description {
  width: 100%;
  word-wrap: break-word;
}
.album-art .bottom-section .song-description p {
  color: white;
}
.album-art .bottom-section .song-description h6 {
  color: white;
  font-size: 16px;
}
.note-card {
  margin-bottom: 25px;
  padding: 15px 15px 10px 15px;
}
.music-info-flex {
  display: flex;
  margin-bottom: 1em;
}
.music-info-flex .music-image {
  height: 120px;
  margin-right: 15px;
  width: 120px;
}
.music-info-flex .music-info {
  font-size: 13px;
}
.music-price-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
.share-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.share-header .share-title {
  font-size: 18px;
  font-weight: bold;
}
.share-container {
  padding: 0 15px 15px;
}
.share-container .share-icons-container {
  display: flex;
  justify-content: space-evenly;
}
.share-container .copy-share-container {
  align-items: center;
  background-color: #f2f2f2;
  display: flex;
  padding: 3px 10px;
}
.share-container .copy-text-input {
  border: 0;
  font-size: 12px;
  width: 100%;
}
.page-loading {
  pointer-events: none;
}
.page-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px 10px;
}
.page-content-child {
  align-items: center;
}
.page-content-child > div {
  width: 100%;
}
.desktop .page-content-child {
  padding: 5px 10px;
}
.page-content-wrapper {
  height: 100%;
  justify-content: space-between;
  min-width: 320px;
  position: relative;
}
.page-content.player-visible .snackbar {
  bottom: 74px;
}
.desktop .page-content.player-visible .snackbar,
.tablet .page-content.player-visible .snackbar {
  bottom: 80px;
}
.page-content .position-sticky {
  bottom: 0;
  position: sticky;
  position: -webkit-sticky;
}
.page-content .position-fixed {
  bottom: 0;
  left: 0;
  margin-bottom: 74px;
  position: fixed;
  width: 100%;
}
.navigation {
  justify-content: space-between;
}
.navigation-wrapper {
  min-height: 56px;
  width: 100%;
  z-index: 99999;
}
.navigation-wrapper .progress {
  margin: initial;
}
.navigation-wrapper.navigation-tab {
  min-height: 104px;
}
.desktop .navigation-wrapper .arrow-back-wrapper {
  display: none;
}
.desktop .navigation-wrapper .cart-icon {
  margin-right: 12px;
}
.navigation-wrapper .login-or-account-info {
  border-left: 1px solid #dadce0;
  margin-left: 5px;
}
.navigation-last-item {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.navigation .search {
  margin: 0 15px;
}
.notification {
  max-height: 800px;
  max-width: 350px;
}
@media (max-width: 420px) {
  .notification {
    height: '100%';
    width: '100%';
  }
}
.notification-header {
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 150px;
}
.notification-footer {
  padding-bottom: 20px;
  text-align: center;
}
.notification .MuiDialogContent-root {
  padding: 5px 20px 0;
}
@media (max-width: 420px) {
  .notification .MuiListItemText-primary {
    font-size: large;
  }
  .notification .MuiListItemText-secondary {
    font-size: medium;
  }
}
.notification .MuiListItemAvatar-root {
  min-width: 40px;
  position: relative;
  bottom: 0px;
}
@media (max-width: 420px) {
  .notification .MuiListItemAvatar-root {
    bottom: 30px;
  }
}
.notification .MuiAvatar-root {
  background-color: #0d904f;
  color: white;
  height: 50px;
  width: 50px;
}
.menu-isDesktopView > li:first-of-type {
  margin-left: 200px;
}
.menu-isDesktopView .navigation-icon {
  background-color: #333333;
  border-right: 1px solid #ffffff;
}
.menu .selected.selected.selected {
  background-color: #0d904f;
}
.menu .selected.selected.selected div,
.menu .selected.selected.selected span {
  color: #ffffff;
}
.footer-wrapper {
  background-color: #535353;
  color: #ffffff;
  padding: 10px 20px;
  width: 100vw;
}
.footer-icons {
  margin-top: 15px;
}
.footer-icons img {
  float: left;
  height: 30px;
}
.footer-social-icons {
  height: 30px;
  margin-right: 2%;
}
.account-info {
  color: #000000;
  padding: 0 16px;
}
.page-content .account-info {
  width: 250px;
}
.player-control {
  align-self: flex-end;
  position: relative;
  width: 100%;
  z-index: 1;
}
.player-control-top {
  display: none;
}
.player-control-bottom {
  height: 74px;
}
.desktop .player-control-bottom,
.tablet .player-control-bottom {
  height: 80px;
}
.player-control-queue {
  background-color: var(--background-color);
  height: 0;
  overflow-y: auto;
  padding: 0 5px;
}
.player-control-cover {
  height: 74px;
  width: 74px;
}
.desktop .player-control-cover,
.tablet .player-control-cover {
  height: 80px;
  width: 80px;
}
.player-control .progress-bar {
  left: 80px;
  padding: 0;
  position: absolute;
  width: calc(100% - 80px);
  z-index: 1;
}
.player-control-maximized {
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.player-control-maximized .player-control-top {
  display: flex;
  flex-direction: column;
}
.player-control-maximized .player-control-bottom {
  opacity: 0.9;
}
.player-control-maximized .player-control-cover {
  height: 54px;
  width: 54px;
}
.player-control-maximized .progress-bar {
  bottom: 74px;
  left: 0;
  top: initial;
  width: 100%;
}
.info-detail {
  padding: 10px;
}
.artist-verification .stepper .button {
  margin-right: 16px;
}
.artist-verification .stepper .contract-form {
  width: 100%;
}
.artist-verification .stepper .instructions .form-input {
  margin-top: 8px;
}
.artist-art {
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 #bac4d9;
  margin: 10px auto;
  max-height: 120px;
  max-width: 120px;
  text-align: center;
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.artist-art-cover .artist-initials {
  font-size: calc(200%);
  vertical-align: middle;
}
.artist-art .stage-name {
  font-size: 10px;
}
.cart .title {
  font-weight: 500;
}
.cart .music-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 10px 0;
}
.desktop .cart .music-flex {
  padding: 30px 15px;
}
.cart .music-flex-item {
  margin-bottom: 10px;
  width: 100%;
}
.desktop .cart .music-flex-item {
  flex: 0 0 55%;
  margin-bottom: 0;
  min-width: 540px;
  padding: 10px;
}
.desktop .cart .music-flex-item .title {
  margin-bottom: 10px;
}
.cart .music-flex-item .music-item-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.cart .music-flex-item .music-item-price .music-price-flex {
  align-items: center;
  align-self: flex-start;
  display: flex;
}
.cart .music-flex-item .music-info-flex {
  display: flex;
  margin-bottom: 1em;
}
.cart .music-flex-item .music-info-flex .music-image {
  height: 120px;
  margin-right: 15px;
  width: 120px;
}
.cart .music-flex-item .music-info-flex .music-info {
  font-size: 13px;
}
.cart .music-flex-summary {
  width: 100%;
}
.desktop .cart .music-flex-summary {
  align-self: flex-start;
  flex: 0 0 25%;
  min-width: 178px;
  padding: 10px;
}
.cart .music-flex-summary .title {
  margin-bottom: 10px;
}
.desktop .cart .music-flex-summary .title {
  margin-bottom: 20px;
}
.cart .music-flex-summary .item-quantity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.desktop .cart .music-flex-summary .item-quantity {
  margin-bottom: 10px;
}
.cart .music-flex-summary .rule {
  display: none;
}
.desktop .cart .music-flex-summary .rule {
  display: block;
  margin-bottom: 20px;
  margin-top: 60px;
}
.cart .music-flex-summary .total-cost {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.desktop .cart .music-flex-summary .total-cost {
  margin-top: 0;
}
.cart .music-flex-summary .total-cost .text {
  font-weight: 500;
}
.cart .music-flex-summary .buy-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}
.desktop .cart .music-flex-summary .buy-button {
  margin-top: 30px;
}
.cart .price-container {
  background-color: #0e9050;
  border: 0;
  color: #ffffff;
  font-size: 1em;
  font-weight: 600;
  margin-left: 10px;
  padding: 5px 10px;
}
.cart .mobile-price-container {
  background-color: #0e9050;
  border: 0;
  color: #ffffff;
  display: inline-flex;
  font-size: 0.75em;
  font-weight: 600;
  margin-top: 5px;
  padding: 7px 15px;
}
.cart-payment .note-card {
  margin-bottom: 10px;
  padding: 15px 15px 10px 15px;
}
.cart-payment .title {
  font-weight: 500;
}
.cart-payment .mobile-price-container {
  background-color: #00984c;
  border: 0;
  color: #ffffff;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
  padding: 7px 15px;
}
.cart-payment .music-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 10px 0;
}
.desktop .cart-payment .music-flex {
  padding: 0;
}
.desktop .cart-payment .music-flex-item {
  margin-bottom: 0;
}
.desktop .cart-payment .music-flex-item .title {
  margin-bottom: 25px;
}
.cart-payment .music-flex-item .music-order-summary {
  margin-bottom: 220px;
  padding: 20px;
}
.desktop .cart-payment .music-flex-item .music-order-summary {
  margin-bottom: 20px;
}
.cart-payment .music-flex-item .music-order-summary .music-item-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.cart-payment .music-flex-item .music-order-summary .music-item-price .music-price-flex {
  align-items: center;
  align-self: flex-start;
  display: flex;
}
.cart-payment .music-flex-item .music-order-summary .music-item-price .music-price-flex .price-container {
  background-color: #00984c;
  border: 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-left: 15px;
  padding: 7px 25px;
}
.cart-payment .music-flex-item .music-order-summary .music-info-flex {
  display: flex;
  margin-bottom: 1em;
}
.cart-payment .music-flex-item .music-order-summary .music-info-flex .music-image {
  height: 120px;
  margin-right: 15px;
  width: 120px;
}
.cart-payment .music-flex-item .music-order-summary .music-info-flex .music-info {
  font-size: 13px;
}
.cart-payment .music-flex-summary {
  bottom: 73px;
  position: fixed;
  width: 100vw;
}
.desktop .cart-payment .music-flex-summary {
  align-self: flex-start;
  bottom: 0;
  flex: 0 0 25%;
  margin-left: 10px;
  position: relative;
}
.cart-payment .music-flex-summary .summary-card {
  padding: 8px 25px;
  width: 100%;
}
.desktop .cart-payment .music-flex-summary .summary-card {
  padding: 20px 25px;
}
.cart-payment .music-flex-summary .payment-card {
  display: none;
}
.desktop .cart-payment .music-flex-summary .payment-card {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  max-width: 300px;
  text-align: center;
}
.cart-payment .music-flex-summary .payment-image {
  display: none;
  padding: 0.5em;
}
.desktop .cart-payment .music-flex-summary .payment-image {
  display: block;
  max-width: 280px;
}
.cart-payment .music-flex-summary .amount {
  color: #00984c;
  font-size: large;
  font-weight: bold;
  margin-bottom: 3px;
  padding-top: 0;
}
.cart-payment .music-flex-summary .price {
  margin-top: 0;
  padding-top: 0;
}
.cart-payment .music-flex-summary .title {
  margin-bottom: 10px;
}
.desktop .cart-payment .music-flex-summary .title {
  margin-bottom: 20px;
}
.cart-payment .music-flex-summary .item-quantity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.desktop .cart-payment .music-flex-summary .item-quantity {
  margin-bottom: 25px;
}
.cart-payment .music-flex-summary .rule {
  display: none;
}
.desktop .cart-payment .music-flex-summary .rule {
  display: block;
  margin-bottom: 20px;
  margin-top: 20px;
}
.cart-payment .music-flex-summary .total-cost {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.desktop .cart-payment .music-flex-summary .total-cost {
  margin-top: 0;
}
.cart-payment .music-flex-summary .total-cost .text {
  font-weight: 500;
}
.cart-payment .music-flex-summary .buy-button {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 15px;
  width: 100%;
}
.desktop .cart-payment .music-flex-summary .buy-button {
  margin-top: 30px;
}
.cart-payment .music-flex-summary .buy-button .update-cart-btn {
  background-color: #ffffff;
  color: #00984c;
}
.desktop .cart-payment .music-flex-summary .buy-button .update-cart-btn {
  background-color: #00984c;
  color: #ffffff;
}
.cart-payment .music-flex-summary .buy-button .buy-now {
  margin-left: 8px;
  width: 50%;
}
.desktop .cart-payment .music-flex-summary .buy-button .buy-now {
  display: none;
}
.cart-payment .music-flex-summary .buy-now-btn {
  display: none;
  margin-top: 25px;
}
.desktop .cart-payment .music-flex-summary .buy-now-btn {
  display: inline-block;
}
.newly-added {
  padding: 5px;
}
.newly-added img {
  height: 200px;
  width: 200px;
}
.newly-added .new-box,
.newly-added .sale-box {
  background: #ffffff;
  border: 2px solid;
  display: block;
  position: absolute;
  text-align: center;
  top: 10px;
}
.newly-added .new-box span,
.newly-added .sale-box span {
  display: block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.06em;
  line-height: 1.5;
  min-width: 41px;
  padding: 3px 5px;
  text-transform: uppercase;
}
.newly-added .new-box {
  border-color: #333333;
  color: #333333;
  left: 10px;
}
.newly-added .new-box:hover {
  color: #333333;
}
.newly-added .sale-box {
  border-color: #0d904f;
  color: #0d904f;
  right: 10px;
}
.newly-added .sale-box .sale-label {
  color: #0d904f;
}
.newly-added .sale-box .price-reduction {
  background: #0d904f;
  color: #ffffff;
  display: block;
  padding-top: 6px;
}
.home-footer-social-icons {
  height: 20px;
  margin-right: 2%;
}
.slides .caption h3 {
  margin-top: 70px;
}
.subscribe-button {
  background-color: #0d904f;
  border-radius: 0;
  height: 50px;
}
.text-after-image {
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
}
.desktop .library-songs {
  justify-content: flex-start;
  width: 70%;
}
.dashboard-music,
.dashboard-contents,
.dashboard-users {
  margin-top: 10px;
}
.dashboard-music .breadcrumb,
.dashboard-contents .breadcrumb,
.dashboard-users .breadcrumb {
  padding: 10px 15px;
}
.dashboard-cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 20px;
}
.desktop .dashboard-cards {
  justify-content: flex-start;
}
.dashboard-cards .dashboard-card {
  flex: 100%;
  margin: 10px 10px;
  max-width: 270px;
}
.desktop .dashboard-cards .dashboard-card {
  flex: 33%;
  max-width: 250px;
}
.dashboard-cards .dashboard-media {
  height: 200px;
}
.payout {
  margin-top: 5px;
}
.payout .payout-center {
  display: flex;
  justify-content: center;
}
.payout .payout-center .payout-card {
  width: 100%;
}
.desktop .payout .payout-center .payout-card {
  padding: 10px 20px;
  width: 70%;
}
.payout .payout-center .payout-card .heading {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;
}
.payout .payout-center .payout-card .heading-text {
  align-self: flex-end;
  font-weight: bold;
  margin-left: 10px;
}
.payout .payout-center .payout-card .mid-section {
  margin-bottom: 15px;
  margin-top: 15px;
}
.payout .payout-center .payout-card .mid-section-info {
  display: flex;
  flex-flow: row wrap;
  font-size: medium;
  justify-content: space-between;
}
.payout .payout-center .payout-card .mid-section-info-title {
  font-size: medium;
}
.payout .payout-center .payout-card .mid-section-info-qty {
  color: #00984c;
}
.desktop .payout .payout-center .payout-card .mid-section-info-qty {
  margin-right: 42px;
}
.payout .payout-center .payout-card .bottom-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}
.payout .payout-center .payout-card .bottom-row .payout-btn {
  margin-top: 10px;
  width: 100%;
}
.desktop .payout .payout-center .payout-card .bottom-row .payout-btn {
  text-align: right;
  width: 30%;
}
.profile-section {
  margin: auto;
  max-width: 450px;
  padding: 0 10px;
  width: 100%;
}
.profile-section .form-element {
  margin-bottom: 10px;
  width: 100%;
}
.profile-section .form-element .inline-element {
  text-align: left;
  width: 47%;
}
.shows {
  padding: 0;
}
.shows .header {
  color: #ffffff;
}
.shows .text-color-primary {
  color: #0d904f;
}
.shows .text-color-secondary {
  color: rgba(0, 0, 0, 0.87);
}
.shows .empty-text {
  margin: auto;
  margin-top: 20px;
  min-width: 100vw;
}
.desktop .shows .empty-text {
  min-width: 80vw;
}
.shows-top-container {
  background-size: cover;
  margin-bottom: 10px;
  position: relative;
}
.shows-top-container .search-container {
  padding: 30px 5%;
}
.shows-top-container .search-container .search-input {
  align-content: stretch;
}
.shows-top-container .search-container .search-input-container {
  flex-grow: 1;
  margin-top: 25px;
}
.shows-top-container .search-container .search-button-container {
  align-self: center;
  margin-top: 25px;
  padding-left: 20px;
}
.shows-top-container .search-container .add-button {
  background-color: #ffffff;
  border-radius: 30px;
  color: #0d904f;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 50px;
  text-decoration: none;
}
.show-body {
  align-content: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}
.desktop .show-body {
  flex-wrap: nowrap;
}
.show-body .shows-featured {
  min-width: 350px;
  width: 100%;
}
.desktop .show-body .shows-featured {
  border-left: 2px solid #0d904f;
  margin-left: auto;
  max-width: 350px;
}
.show-body .shows-featured-header {
  background-color: #0d904f;
  color: #ffffff;
  padding: 30px 60px;
  text-align: center;
}
.show-body .shows-featured-cards {
  padding: 15px;
}
.show-body .shows-featured-cards .featured-card {
  height: 100px;
  margin-bottom: 5px;
  padding: 12px;
  text-decoration: none;
}
.show-body .shows-featured-cards .featured-card-img {
  height: 100%;
  width: 80px;
}
.show-body .shows-featured-cards .featured-card .details {
  margin-left: 20px;
}
.show-create-text-input {
  height: 40px;
}
.show-create-top-container {
  background-color: #0d904f;
  padding: 20px;
}
.show-create-top-container .create-header {
  color: #ffffff;
}
.show-create-top-container .text-container {
  margin: 0 auto;
  max-width: 600px;
}
.desktop .show-create-top-container {
  padding: 40px;
}
.show-create-body {
  margin: 0 auto;
  max-width: 650px;
  padding-top: 5px;
}
.desktop .show-create-body {
  padding: 30px 80px;
}
.show-create-body .label {
  margin-top: 10px;
}
.show-create-body .chip-input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 5px 0 0 10px;
}
.show-create-body .actions {
  margin-top: 30px;
}
.show-create-body .tickets-input-field {
  margin: 20px 0;
}
.show-create-body .date-time-input {
  margin: 12px 0;
}
.desktop .show-create-body .date-time-input {
  margin: 12px 20px 12px 0;
  width: 40%;
}
.show-create-body .navigate {
  display: block;
}
.desktop .show-create-body .navigate {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.show-create-body .ticket-type-chip {
  background-color: #0d904f;
  color: #ffffff;
  height: 35px;
  margin: 25px 10px 0 0;
}
.show-create-body .ticket-type-input {
  height: 35px;
  margin-right: 10px;
  width: 100%;
}
.desktop .show-create-body .ticket-type-input {
  width: 100px;
}
.show-create-body .select-commission {
  margin-top: 16px;
  width: 100%;
}
.desktop .show-create-body .venue-input {
  clear: both;
}
.show-view {
  padding: 25px 10px;
}
.show-view .top-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding: 10px 0;
}
.show-view .top-row .create-show-button {
  color: #ffffff;
  text-decoration: none;
  width: 100%;
}
.desktop .show-view .top-row .create-show-button {
  margin-bottom: 10px;
  width: auto;
}
.show-preview {
  padding: 25px;
}
.show-preview .image {
  margin-top: 20px;
  width: 100%;
}
.desktop .show-preview .image {
  max-height: 300px;
  max-width: 300px;
}
.show-preview .info-header {
  font-size: 15px;
}
.show-preview .artists {
  margin: 20px 0;
}
.desktop .show-preview .artists {
  margin-top: 60px;
}
.show-preview .artist {
  border-radius: 4px;
  margin-right: 10px;
}
.show-preview .buttons {
  margin-top: 20px;
}
.show-preview .buttons .modify-button {
  margin-right: 20px;
}
.desktop .show-preview .buttons .modify-button {
  border: 0;
}
.show-preview-details {
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 10px;
}
.desktop .show-preview-details {
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
}
.show-preview-details .info {
  margin-top: 20px;
}
.show-preview-details .info-ticket {
  flex-direction: column;
  flex-wrap: wrap;
}
.desktop .show-preview-details .info-ticket {
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 30px;
}
.show-preview-details .info-ticket-types {
  margin-top: 5px;
}
.desktop .show-preview-details .info-ticket-types {
  margin-right: 20px;
}
.desktop .show-preview-details .info {
  border-right: 1px solid #b9babb;
  flex: 1 1 100px;
  min-width: 150px;
  padding: 0 20px;
}
.desktop .show-preview-details .info:last-child {
  border-right: 0;
}
.book-show .page-content-child {
  max-width: 800px;
}
.book-show .description {
  padding: 10px 15px;
}
.book-show .description .title {
  font-weight: 500;
}
.book-show .description .summary {
  margin-bottom: 10px;
}
.book-show .description .summary-title {
  font-weight: bold;
}
.book-show .description .summary p {
  margin: 2px 0;
}
.book-show .description .artists {
  margin-right: 7px;
  margin-top: 10px;
}
.book-show .description-footer {
  background-color: var(--background-color);
}
.book-show .description-footer .summary-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
}
.book-show .description-footer .get-ticket-btn {
  justify-content: center;
  width: 90%;
}
.book-show .show-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}
.book-show .show-flex .flex-left {
  flex: 1 1 57%;
  margin-right: 20px;
}
.book-show .show-flex .flex-left .ticket-card {
  margin-top: 20px;
}
.book-show .show-flex .flex-right {
  flex: 0 1 40%;
  max-width: 280px;
  padding: 10px 15px;
}
.book-show .show-flex .flex-right .artists {
  margin-right: 7px;
  margin-top: 10px;
}
.book-show .title {
  margin: 10px 0 10px 0;
}
.book-show .summary {
  margin-bottom: 15px;
}
.book-show .summary-title {
  color: #00984c;
}
.book-show .note-summary {
  margin-bottom: 48px;
  margin-top: 7px;
  width: 100%;
}
.payment .page-content-child {
  max-width: 800px;
}
.payment .payment-card {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
}
.payment .payment-image {
  background-color: white;
  display: block;
  margin-bottom: 24px;
  max-width: 325px;
}
.payment .amount {
  color: #00984c;
  font-size: large;
  font-weight: bold;
  margin-bottom: 3px;
  padding-top: 0;
}
.payment .price {
  margin-top: 0;
  padding-top: 0;
}
.payment .payment-btn {
  background-color: #00984c;
  border: 0;
  color: #ffffff;
  margin-top: 10px;
  outline: none;
  padding: 10px 30px;
  width: 45%;
}
.payment .show-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 20px;
  width: 100%;
}
.payment .show-flex .flex-left {
  flex: 0 1 57%;
  margin-right: 20px;
}
.payment .show-flex .flex-left .note-card {
  padding: 15px 15px 10px 15px;
}
.payment .show-flex .flex-left .ticket-card {
  margin-top: 20px;
  padding: 15px;
}
.payment .show-flex .flex-right {
  flex: 0 1 40%;
  max-width: 280px;
  padding: 10px 15px;
}
.payment .show-flex .flex-right .artists {
  margin-right: 7px;
  margin-top: 10px;
}
.payment .title {
  color: #00984c;
  margin: 10 0 10px 0;
}
.payment .summary {
  margin-bottom: 10px;
}
.payment .summary-title {
  color: #00984c;
}
.payment .order-summary {
  display: inline-flex;
  padding: 15px 10px;
}
.payment .order-summary .summary-text {
  padding-left: 10px;
}
.payment .payment-footer .payment-note {
  margin-left: 15px;
  margin-top: 10px;
}
.payment .payment-footer .payment-options .payment-btn {
  background-color: #00984c;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
  outline: none;
  padding: 10px 30px;
  width: 45%;
}
.payment-confirmation {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 500px;
}
.payment-confirmation .check-icon {
  font-size: 70px;
  margin-top: 5px;
}
.payment-confirmation .check-icon .desktop,
.tablet .payment-confirmation .check-icon {
  margin-top: 10px;
}
.payment-confirmation-title {
  color: #00984c;
  margin-bottom: 48px;
  margin-top: 20px;
  text-align: center;
}
.payment-confirmation .completed-payment-footer {
  bottom: 0;
  left: 0;
  margin-bottom: 74px;
  margin-top: 70px;
  position: absolute;
  width: 100%;
}
.desktop .payment-confirmation .completed-payment-footer {
  margin-bottom: 20px;
  margin-top: 140px;
  position: relative;
}
.payment-confirmation .completed-payment-footer .summary-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px 10px;
}
.payment-confirmation .completed-payment-footer .summary-button button {
  max-width: 200px;
}
.payment-confirmation .completed-payment-footer .get-ticket-btn {
  justify-content: center;
  width: 43%;
}
.payment .summary-note {
  margin-bottom: 60px;
  margin-top: 20px;
}
.payment .summary-note-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.payment .summary-footer {
  background-color: var(--background-color);
}
.payment .summary-footer .summary-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px 10px;
}
.payment .summary-footer .get-ticket-btn {
  justify-content: center;
  width: 43%;
}
.primary-button {
  background-color: #0d904f;
}
.flex {
  display: flex;
  flex-direction: column;
}
.flex-fixed {
  flex: 0 0 auto;
}
.flex-grow {
  flex: 1 0 auto;
}
.flex-elastic {
  flex: 1 1 auto;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-direction-row {
  flex-direction: initial;
}
.flex-pull-left {
  justify-content: flex-start;
}
.flex-pull-right {
  justify-content: flex-end;
}
.flex-row {
  flex-wrap: wrap;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-evenly {
  justify-content: space-evenly;
}
.flex-justify-space-between {
  align-items: center;
  justify-content: space-between;
}
.flex-justify-space-around {
  align-items: center;
  justify-content: space-around;
}
.grid {
  align-items: flex-start;
  display: grid;
  grid-auto-rows: minmax(auto, auto);
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
}
.large .grid.album-art-grid {
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
}
@media (min-width: 375px) {
  .grid.dj-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
}
@media (min-width: 450px) {
  .grid.dj-grid {
    grid-template-columns: repeat(auto-fill, minmax(157px, 190px));
  }
}
.equalizer {
  transform: rotate(180deg);
  width: calc(50%);
}
.equalizer.animate .bar {
  animation: equalize 1.25s steps(25, end) 0s infinite;
  height: initial;
}
.equalizer.animate .bar:nth-child(1) {
  animation-duration: 1.9s;
}
.equalizer.animate .bar:nth-child(2) {
  animation-duration: 2s;
}
.equalizer.animate .bar:nth-child(3) {
  animation-duration: 2.3s;
}
.equalizer .bar {
  fill: var(--text-color);
  height: 10px;
  width: 30%;
}
@keyframes equalize {
  0% {
    height: 60px;
  }
  4% {
    height: 50px;
  }
  8% {
    height: 40px;
  }
  12% {
    height: 30px;
  }
  16% {
    height: 20px;
  }
  20% {
    height: 30px;
  }
  24% {
    height: 40px;
  }
  28% {
    height: 10px;
  }
  32% {
    height: 40px;
  }
  36% {
    height: 60px;
  }
  40% {
    height: 20px;
  }
  44% {
    height: 40px;
  }
  48% {
    height: 70px;
  }
  52% {
    height: 30px;
  }
  56% {
    height: 10px;
  }
  60% {
    height: 30px;
  }
  64% {
    height: 50px;
  }
  68% {
    height: 60px;
  }
  72% {
    height: 70px;
  }
  76% {
    height: 80px;
  }
  80% {
    height: 70px;
  }
  84% {
    height: 60px;
  }
  88% {
    height: 50px;
  }
  92% {
    height: 60px;
  }
  96% {
    height: 70px;
  }
  100% {
    height: 80px;
  }
}
body.ios .page-content {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
}
.brand-logo {
  background-color: #0d904f;
  background-position: 50%, 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 36px;
  width: 100%;
}
.brand-logo.left {
  background-position: 0, 50%;
}
.brand-logo.green {
  background-color: initial;
  height: 50px;
  width: 150px;
}
@media only screen and (max-width: 600px) {
  /* For small: */
}
@media only screen and (min-width: 600px) {
  /* For large: */
  .site-logo {
    left: 50%;
  }
  .navigation-wrapper {
    min-height: 64px;
  }
  .navigation-wrapper.navigation-tab {
    margin-left: 230px;
    margin-top: 65px;
    min-height: initial;
  }
  .grid {
    grid-gap: 15px;
  }
  .page-content {
    margin-left: 230px;
  }
  .page-content.no-navigation {
    margin-left: initial;
  }
  .page-content.artist .top,
  .page-content.dj .top {
    flex-direction: row;
  }
  .profile-link {
    width: 90px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 993px) {
  .grid {
    grid-gap: 10px;
  }
  .page-content {
    margin-left: initial;
  }
  .page-content.library {
    margin-top: 50px;
  }
}
